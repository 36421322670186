<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            BUNGEN VERKKOSIVUSTOJEN JA SOVELLUSTEN KÄYTTÖEHDOT
        </p>

        <p class="mt-6 mb-4">
            Rekisteröitymällä vapaaehtoisesti alla oleville Bunge Limitedin ja/tai sen tytäryhtiön (”Bunge) verkkosivustoille ja/tai sovelluksiin, vahvistan lukeneeni ja ymmärtäneeni seuraavat käyttöehdot ja sitoudun noudattamaan niitä:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Bungen verkkosivustojen ja/tai sovellusten käyttäminen, myös henkilökohtaisen laitteen kautta, on vapaaehtoista. Sen tarkoituksena on vahvistaa Bungen viestintää työntekijöidensä kanssa toimimalla lisäkanavana olemassa olevien viestintäkanavien lisäksi.
                </li>
                <li>
                    Voin milloin tahansa lopettaa verkkosivustojen käytön ja/tai poistaa sovellukset ja lopettaa niiden käytön.
                </li>
                <li>
                    Henkilökohtaisen laitteeni (henkilökohtaisten laitteitteni) käyttöön sovelletaan Bungen käytäntöjä, mukaan lukien [linkistä] saatavilla oleva Bring Your Own Device Policy ("BYOD").
                </li>
                <li>
                    Bunge ei korvaa henkilökohtaisen laitteeni hankinnasta, käytöstä, ylläpidosta, korjauksesta ja/tai vaihdosta aiheutuvia kustannuksia, mukaan lukien datasiirron kustannuksia, vaan ne ovat minun vastuullani.
                </li>
                <li>
                    Bunge ei vaadi minua käyttämään verkkosivuja ja/tai sovelluksia työni ulkopuolella, enkä ole oikeutettu ylitöihin tai lisäkorvauksiin, jos käytän verkkosivustoja ja/tai sovelluksia työajan ulkopuolella.
                </li>
                <li>
                    Verkkosivustojen ja/tai sovellusten salasanoja ei voi siirtää, ja ne on suojattava asianmukaisesti paljastumiselta.
                </li>
                <li>
                    Muilta henkilöitä on kiellettyä käyttää verkkosivustoja ja/tai sovelluksia tunnuksillani tai eivätkä muut henkilöt saa päästä käsiksi mobiililaitteeni numeroon lähetettyihin todennuskoodeihin.
                </li>
                <li>
                    Minun on kunnioitettava kaikkien niiden tietojen luottamuksellisuutta, jotka ovat saatavilla tai jotka olen vastaanottanut verkkosivustojen ja/tai sovellusten kautta, mukaan lukien kaikki mobiililaitteeseeni lähetetyt luottamukselliset tiedot. En saa paljastaa ja/tai käyttää kyseisiä tietoja mihinkään muuhun tarkoitukseen kuin siihen, mihin on lupa Bungen Eettisten sääntöjen sekä Bungen käytäntöjen ja menettelytapojen mukaisesti.
                </li>
                <li>
                    Sitoudun ilmoittamaan Bungelle viipymättä kaikista muutoksista tässä lomakkeessa ilmoitettuun numeroon, mikäli haluan edelleen käyttää Bungen verkkosivustoja ja/tai sovelluksia.
                </li>
                <li>
                    Jos laitteeni katoaa tai varastetaan tai se hävitetään muulla tavoin, ilmoitan siitä välittömästi Bungelle. Sitoudun poistamaan ennen laitteen hävittämistä kaikki Bungen sovellukset, jotka olen ladannut.
                </li>
                <li>
                    Sitoudun ilmoittamaan Bungelle välittömästi, jos saan tiedon kyseisten laitteiden, verkkosivustojen ja/tai sovellusten tai tunnusteni luvattomasta käytöstä.
                </li>
                <li>
                    Väärinkäytösten yhteydessä ja viimeistään sinä päivänä, jona en ole enää työsuhteessa Bungeen, pääsyni verkkosivustoille ja sovelluksiin poistetaan käytöstä.
                </li>
                <li>
                    Tunnustan, että kaikki sovellusten sisältö kuuluu Bungelle tai on lisensoitu Bungelle ja on suojattu tekijänoikeuslainsäädännöllä, ja sitoudun olemaan käyttämättä tällaista sisältöä sovellusten ulkopuolella tai työsuhteeni päättymisen jälkeen, tarkoituksesta riippumatta, ellei Bunge anna siihen lupaa.
                </li>
                <li>
                    Tähän käyttöoikeuslomakkeeseen sovelletaan paikallisia voimassa olevia aineellisen oikeuden ja menettelyoikeuden lakeja.
                </li>
                <li>
                    Bunge ei ole vastuussa tunnusteni väärinkäytöstä, jos en ole suojannut tietojani asianmukaisesti, olen luovuttanut tunnukseni ei-auktorisoiduille henkilöille, tai en ole ilmoittanut kadonneesta tai varastetusta laitteesta välittömästi Bungelle.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Henkilötietojen suojaaminen
            </p>

            <p>
                Ymmärrän, että henkilötietojani käsitellään verkkosivustojen ja/tai Bungen sovellusten kautta [Bunge Europe Työntekijän tietosuojaselosteen] ja sovellettavien lakien mukaisesti. Bunge käsittelee henkilötietojani ainoastaan seuraavia tarkoituksia varten:
            </p>

            <p>
                Lähettääkseen kaksivaiheiseen todennukseen liittyviä koodeja ja muita kirjautumista koskevia tietoja alla ilmoitettuun mobiililaitteeni numeroon, jotta minulla olisi pääsy Bungen verkkosivustoille ja/tai sovelluksiin yhden tai useamman henkilökohtaisen laitteeni kautta ja/tai Bungen laitteilla.
            </p>

            <p>
                Bunge poistaa jäljempänä ilmoittamani yksityisen puhelinnumeroni 5 (viiden) päivä kuluessa siitä päivästä, jolloin lopetan järjestelmän käytön, tai käyttöoikeuteni poistetaan Bungen toimesta.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>