<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            TERMO DE USO PARA ACESSO AO SITE E/OU APLICATIVO CORPORATIVO
        </p>

        <p>
            Eu, empregado da Bunge Alimentos S.A. (“Bunge”), venho por meio deste Termo informar o endereço meu e-mail
            para solicitar, de forma voluntária, o envio de senha de acesso para efetuar o meu cadastro como usuário do
            site e/ou aplicativo disponibilizado pela Bunge, com o intuito de acessar e receber informações de meu
            interesse relativas ao meu contrato de trabalho.
        </p>

        <p class="mt-6 mb-4">
            Declaro, ainda, que tomei ciência e concordo com as seguintes condições:
        </p>

        <v-divider></v-divider>

        <ol class="legal-policy-content">
            <li>
                O acesso ao site e/ou aplicativo disponibilizado pela Bunge, inclusive por meio do dispositivo pessoal,
                é voluntário, opcional e visa fortalecer a comunicação da Bunge com seus empregados, sendo mais um canal
                de comunicação aos existentes.
            </li>
            <li>
                Poderei, a qualquer momento, deixar de acessar o site e/ou desinstalar o aplicativo e deixar de utilizá-lo.
            </li>
            <li>
                As despesas de aquisição, uso, manutenção, reparo e/ou troca de meu dispositivo pessoal, inclusive no que
                se refere ao custo de planos de dados, não serão reembolsadas pela Bunge, mas correrão por minha conta exclusiva.
            </li>
            <li>
                O aplicativo e/ou site é acessível mediante usuário e senha pessoais e intransferíveis, os quais me
                comprometo a manter em absoluto sigilo.
            </li>
            <li>
                Não devo permitir que quaisquer pessoas, nem mesmo familiares ou pessoas de minha confiança, acessem
                ou usem o aplicativo e/ou site em meu nome.
            </li>
            <li>
                Devo manter em estrito sigilo todas as informações e dados disponíveis no aplicativo e/ou site, bem
                como as recebidas no e-mail informado neste Termo, não podendo divulgar e/ou utilizar tais informações
                para qualquer outro fim que não esteja autorizado pela Bunge, de acordo com as regras do meu contrato
                de trabalho, bem como aquelas  estabelecidas no Código de Conduta e nas políticas/procedimentos da Bunge.
            </li>
            <li>
                Aceito o uso do aplicativo e/ou site para assinatura digital de documentos atinentes a meu contrato de
                trabalho com a Bunge.
            </li>
            <li>
                Reconheço que a realização de minha assinatura digital por meio do aplicativo e/ou site constituirá
                manifestação inequívoca de minha vontade, sendo válida e exigível para quaisquer fins.
            </li>
            <li>
                Devo informar a Bunge sobre qualquer alteração no endereço do e-mail informado neste Termo.
            </li>
            <li>
                Declaro ser a única pessoa com acesso à caixa de e-mail informada neste Termo e a única que conhece
                a senha de acesso à referida caixa.
            </li>
        </ol>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>