<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            DIE GESCHÄFTSBEDINGUNGEN FÜR DIE HR- UND KOMMUNIKATIONS-WEBSITES UND -APPS VON BUNGE
        </p>

        <p>
            Indem ich mich unten freiwillig auf den HR- und Kommunikationswebsites und/oder Apps von Bunge
            Global SA und/oder seinen verbundenen Unternehmen (“Bunge“) registriere, bestätige ich, dass ich
            die folgenden Nutzungsbedingungen gelesen und verstanden habe und stimme deren Einhaltung zu
            mit ihnen:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Der Zugriff auf Bunge-Websites und/oder -Apps, auch über ein persönliches Gerät, ist
                    freiwillig, optional und zielt darauf ab, die Kommunikation von Bunge mit seinen Mitarbeitern
                    zu stärken, einen zusätzlichen Kommunikationskanal zu den bestehenden zu schaffen und den
                    Zugriff auf bestimmte digitale Dienste zu ermöglichen Informationen an Bunges Mitarbeiter,
                    insbesondere an solche, denen keine Firmengeräte und E-Mail-Adressen zur Verfügung
                    gestellt werden.
                </li>
                <li>
                    Ich kann jederzeit die Nutzung der Websites beenden und/oder die Apps deinstallieren und
                    die Nutzung beenden.
                </li>
                <li>
                    Die Nutzung meiner persönlichen Geräte unterliegt den geltenden Richtlinien von Bunge.
                </li>
                <li>
                    Ich werde nur meine angegebene private E-Mail-Adresse nur für den Zugriff auf Bunge Websites und/oder -Apps verwenden, falls mir keine geschäftliche E-Mail-Adresse zur
                    Verfügung gestellt wurde. Mir ist bekannt, dass die Verwendung meiner geschäftlichen E-Mail Adresse für diese Verbindung verpflichtend ist, sofern mir eine solche E-Mail-Adresse zur
                    Verfügung gestellt wurde.
                </li>
                <li>
                    Die Kosten für die Anschaffung, Nutzung, Wartung, Reparatur und/oder Änderung meines
                    persönlichen Geräts, einschließlich der Kosten für Datentarife, werden von Bunge nicht
                    erstattet, sondern gehen zu meinen Lasten.
                </li>
                <li>
                    Bunge verlangt nicht, dass ich die Websites und/oder Apps außerhalb der Arbeitszeit
                    konsultiere, und ich habe keinen Anspruch auf Überstunden oder zusätzliche Vergütung, wenn
                    ich außerhalb der Arbeitszeit auf die Websites und/oder Apps zugreife.
                </li>
                <li>
                    Passwörter für die Websites und/oder Apps sind nicht übertragbar und sollten angemessen
                    vor Offenlegung geschützt werden.
                </li>
                <li>
                    Anderen Personen ist es untersagt, die Websites und/oder Apps in meinem Namen zu nutzen
                    oder auf an meine E-Mail-Adresse gesendete Authentifizierungscodes zuzugreifen.
                </li>
                <li>
                    Ich muss die Vertraulichkeit aller auf den Websites und/oder Apps verfügbaren oder über sie
                    empfangenen Informationen beachten, einschließlich aller vertraulichen Informationen, die
                    an meine E-Mail-Adresse und/oder mein Mobilgerät gesendet werden. Ich darf diese
                    Informationen zu keinem anderen Zweck offenlegen und/oder verwenden als zu dem von
                    Bunge gemäß dem Verhaltenskodex von Bunge und den Richtlinien und Verfahren von Bunge
                    genehmigten Zweck.
                </li>
                <li>
                    Ich bin damit einverstanden, Bunge unverzüglich über jede Änderung zu informieren, die ich
                    an der in diesem Formular angegebenen E-Mail-Adresse und/oder Mobilgerätenummer
                    vornehme, soweit ich weiterhin auf die Websites und/oder Apps zugreifen möchte.
                </li>
                <li>
                    Wenn mein Gerät verloren geht, gestohlen oder anderweitig entsorgt wird, werde ich Bunge
                    unverzüglich informieren und bin damit einverstanden, dass ich vor der Entsorgung eines
                    Geräts alle von mir installierten Bunge-Apps entferne.
                </li>
                <li>
                    Ich verpflichte mich, Bunge unverzüglich zu informieren, wenn mir ein unbefugter Zugriff auf
                    die Websites und/oder Apps oder meine für den Zugriff verwendeten Zugangsdaten bekannt
                    wird.
                </li>
                <li>
                    Im Falle eines Missbrauchs, spätestens jedoch zu dem Zeitpunkt, an dem ich nicht mehr bei
                    Bunge angestellt bin, wird mein Zugriff auf die Websites und Apps gesperrt.
                </li>
                <li>
                    Ich erkenne an, dass alle Inhalte in den Apps Bunge gehören oder für Bunge lizenziert sind und
                    durch Urheberrechtsgesetze geschützt sind, und ich erkläre mich damit einverstanden, solche
                    Inhalte nicht außerhalb der Apps oder nach Beendigung meines Arbeitsverhältnisses zu
                    verwenden, unabhängig vom Zweck, es sei denn, Bunge erlaubt dies.
                </li>
                <li>
                    Dieses Zugangsformular unterliegt den geltenden materiellen und verfahrensrechtlichen
                    Gesetzen des Landes, in dem ich beschäftigt bin.
                </li>
                <li>
                    Bunge kann nicht für einen Missbrauch meiner Zugangsdaten haftbar gemacht werden, wenn
                    ich keinen angemessenen Offenlegungsschutz angewendet habe, meine Zugangsdaten an
                    andere Unbefugte weitergegeben habe oder Bunge ein verlorenes oder gestohlenes Gerät
                    nicht unverzüglich gemeldet habe.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Schutz personenbezogener Daten
            </p>

            <p>
                Hiermit werde ich darüber informiert, dass meine über die Websites und/oder die Apps
                verarbeiteten personenbezogenen Daten im Einklang mit der DSGVO verarbeitet werden
                Bunge Europe Employee Data Protection Notice und die geltenden Gesetze. Bunge verarbeitet
                meine personenbezogenen Daten auf der Grundlage meiner Einwilligung ausschließlich zum
                Zweck des Abonnements, des Registrierungsprozesses und der Verwaltung von Zugangsdaten
                (Zurücksetzen des Passworts) sowie für andere Informationen im Zusammenhang mit dem
                Zugriff auf die von mir angegebene E-Mail-Adresse, damit ich über mein Mobiltelefon darauf
                zugreifen kann von Zeit zu Zeit auf einem oder mehreren meiner persönlichen Geräte
                und/oder Bunge-Geräten auf Bunge-Websites und/oder -Apps zugreifen.
            </p>

            <p>
                Ich verstehe, dass eine angemessene Sicherheit personenbezogener Daten gewährleistet ist
                und dass diese vor unbefugter oder rechtswidriger Verarbeitung sowie vor versehentlichem
                Verlust, Zerstörung oder Beschädigung geschützt sind.
            </p>

            <p>
                Wenn ich keine geschäftliche E-Mail-Adresse habe, um mit meinem persönlichen Gerät auf
                Bunge-Websites und/oder -Apps zuzugreifen, wird meine private E-Mail-Adresse, die ich für
                den Zugriff auf Bunge-Websites und/oder -Apps angegeben habe, von Bunge innerhalb von 5
                (fünf) Tagen ab dem Datum gelöscht bei dem ich die Nutzung des Systems beende und/oder
                mein Zugang von Bunge deaktiviert wird. Bei Beendigung des Arbeits- oder
                Vertragsverhältnisses wird meine persönliche E-Mail-Adresse unverzüglich gelöscht.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>
