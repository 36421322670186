<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            CONDICIONES DE USO DEL SITIO WEB Y/O APP CORPORATIVO
        </p>

        <p>
            En mi carácter de empleado de Bunge [completar razón social]. (“Bunge”), vengo por medio del presente formulario a informar mi cuenta de correo electrónico para solicitar en forma voluntaria el envío de la contraseña de acceso para registrarme como usuario del sitio web y/o app para dispositivos móviles, disponibilizado por Bunge para poder acceder y recibir información relacionada con mi contrato de trabajo.
        </p>

        <p class="mt-6 mb-4">
            Declaro conocer y estar de acuerdo con las siguientes condiciones:
        </p>

        <v-divider></v-divider>

        <ol class="legal-policy-content">
            <li>
                El acceso al sitio web y/o app, inclusive por medio de un dispositivo móvil personal, es voluntario,
                optativo y tiene como objetivo fortalecer la comunicación de Bunge con sus empleados, siendo un canal
                de comunicación adicional a los ya existentes.
            </li>
            <li>
                A cualquier momento, podré dejar de utilizar el sitio web, desinstalar la app y dejar de utilizarlo.
            </li>
            <li>
                La contraseña de acceso a la app y/o sitio web son intransferibles.
            </li>
            <li>
                Está prohibido que cualquier otra persona utilice el sitio web y/o la app en mi nombre.
            </li>
            <li>
                Debo respetar la confidencialidad de toda la información disponible en la app y sitio web como también
                la recibida al correo electrónico informado en este formulario, no pudiendo divulgar y/o utilizar dicha
                información para otro fin que no sea el autorizado por Bunge de acuerdo al Código de Conducta y las
                políticas de Bunge que he leído, comprendido y firmado.
            </li>
            <li>
                Acepto el uso de la app y/o sitio web para firmar digitalmente los documentos relacionados con mi contrato
                de trabajo con Bunge.
            </li>
            <li>
                Reconozco que mi firma digital por medio de la app y/o sitio web constituirá una manifestación inequívoca
                de mi voluntad, siendo válida y exigible para cualquier fin.
            </li>
            <li>
                Me comprometo a informar a Bunge cualquier cambio que realice en la casilla de correo electrónico informada
                en este formulario.
            </li>
            <li>
                Declaro ser la única persona con acceso a la casilla de correo electrónico informada en este formulario
                y el único conocedor de la contraseña de acceso a dicha cuenta. Asimismo informo que la casilla de correo
                electrónico informada es gratuita y no implica gastos de ningún tipo para el suscripto.
            </li>
        </ol>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>