<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            WARUNKI KORZYSTANIA Z WITRYN I APLIKACJI BUNGE
        </p>

        <p class="mt-6 mb-4">
            Rejestrując się dobrowolnie poniżej na stronach internetowych i/lub w aplikacjach Bunge Limited i/lub jej ("Bunge"), potwierdzam, że zapoznałem się z poniższymi warunkami użytkowania i zgadzam się ich przestrzegania.
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Dostęp do stron internetowych i/lub Aplikacji Bunge, w tym za pośrednictwem urządzenia osobistego, jest dobrowolny, opcjonalny i ma na celu wzmocnienie komunikacji Bunge z pracownikami, będąc dodatkowym kanałem komunikacji do już istniejących.
                </li>
                <li>
                    W każdej chwili mogę zaprzestać korzystania ze stron internetowych i/lub odinstalować Aplikacje i przestać z nich korzystać.
                </li>
                <li>
                    Korzystanie z mojego osobistego urządzenia (urządzeń) podlega obowiązującym zasadom Bunge, w tym m.in. polityki Bring Your Own Device Policy ("BYOD").
                </li>
                <li>
                    Koszty nabycia, użytkowania, konserwacji, naprawy i/lub zmiany mojego urządzenia osobistego, w tym koszty planów transmisji danych, nie będą zwracane przez Bunge, lecz będą ponoszone na mój koszt.
                </li>
                <li>
                    Bunge nie wymaga od mnie korzystania z witryn internetowych i/lub Aplikacji poza godzinami pracy.
                </li>
                <li>
                    Hasła do stron internetowych i/lub Aplikacji są niezbywalne i powinny być odpowiednio zabezpieczone przed ujawnieniem.
                </li>
                <li>
                    Zabrania się innym osobom korzystania ze stron internetowych i/lub Aplikacji w moim imieniu lub dostępu do jakichkolwiek kodów uwierzytelniających przesyłanych na numer mojego urządzenia mobilnego.
                </li>
                <li>
                    Muszę przestrzegać poufności wszystkich informacji dostępnych w lub otrzymanych za pośrednictwem stron internetowych i/lub Aplikacji, w tym wszelkich informacji poufnych przesłanych na mój numer urządzenia mobilnego. Nie wolno mi ujawniać i/lub wykorzystywać tych informacji do celów innych niż te, do których upoważnił mnie przez Bunge zgodnie z Kodeksem Postępowania Bunge oraz politykami i procedurami Bunge.
                </li>
                <li>
                    Zobowiązuję się do niezwłocznego informowania Bunge o wszelkich zmianach dotyczących numeru urządzenia mobilnego podanego w niniejszym formularzu, w zakresie, w jakim nadal będę chciał korzystać ze stron internetowych i/lub Aplikacji.
                </li>
                <li>
                    Jeśli moje urządzenie zostanie zagubione lub skradzione, lub w inny sposób zutylizowane, natychmiast poinformuję Bunge i zgadzam się, iż przed pozbyciem się urządzenia usunę wszystkie zainstalowane przeze mnie Aplikacje Bunge.
                </li>
                <li>
                    Zobowiązuję się do natychmiastowego poinformowania Bunge, jeśli dowiem się o jakimkolwiek nieautoryzowanym dostępie do stron internetowych i/lub Aplikacji lub moich danych uwierzytelniających użytych do uzyskania dostępu.
                </li>
                <li>
                    W przypadku jakiegokolwiek nadużycia, a najpóźniej w dniu, w którym przestanę być zatrudniony przez Bunge, mój dostęp do stron internetowych i Aplikacji zostanie dezaktywowany.
                </li>
                <li>
                    Przyjmuję do wiadomości, że wszystkie treści zawarte w Aplikacjach należą do Bunge lub są przez Bunge licencjonowane i są chronione prawami autorskimi i zgadzam się nie wykorzystywać żadnych takich treści poza Aplikacjami, lub po zakończeniu mojego zatrudnienia, niezależnie od celu, chyba że Bunge wyrazi na to zgodę.
                </li>
                <li>
                    Niniejszy formularz dostępu podlega obowiązującym przepisom prawa materialnego i proceduralnego kraju, w którym jestem zatrudniony.
                </li>
                <li>
                    Bunge nie ponosi odpowiedzialności za niewłaściwe wykorzystanie moich danych uwierzytelniających, jeżeli nie zastosowałem odpowiedniej ochrony przed ujawnieniem, przekazałem moje dane uwierzytelniające innym nieupoważnionym osobom lub nie zgłosiłem niezwłocznie Bunge zagubionego lub skradzionego urządzenia.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Ochrona danych osobowych
            </p>

            <p>
                Niniejszym przyjmuję do wiadomości, iż moje dane osobowe są przetwarzane za pośrednictwem stron internetowych i/lub Aplikacji zgodnie z [Informacją o ochronie danych osobowych pracowników Bunge Europe - link] oraz obowiązującymi przepisami prawa.
            </p>

            <p>
                Bunge będzie przetwarzać moje dane osobowe wyłącznie w celu wysyłania kodów związanych z dwuskładnikowym uwierzytelnianiem oraz innych informacji związanych z dostępem, na mój numer urządzenia mobilnego podany poniżej, abym mógł mieć dostęp za pośrednictwem mojego urządzenia mobilnego do stron internetowych Bunge i/lub Aplikacji od czasu do czasu na jednym lub kilku moich urządzeniach osobistych i/lub Bunge.
            </p>

            <p>
                Mój prywatny numer telefonu udostępniony na podstawie niniejszego dokumentu zostanie usunięty przez Bunge w ciągu 5 (pięciu) dni od dnia, w którym przestanę korzystać z systemu lub mój dostęp zostanie dezaktywowany przez Bunge.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>