<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            TERMINI E CONDIZIONI DI UTILIZZO DEI SITI WEB E DELLE APP DI BUNGE
        </p>

        <p class="mt-6 mb-4">
            Registrandomi volontariamente di seguito ai siti web e/o alle app di Bunge Limited e/o delle sue affiliate
            ("Bunge"), dichiaro di aver letto e compreso i seguenti termini e condizioni d'uso ed accetto di rispettarli:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    L'accesso ai siti web e/o alle App di Bunge, anche attraverso un dispositivo personale, è volontario,
                    facoltativo e ha lo scopo di rafforzare la comunicazione di Bunge con i propri dipendenti, costituendo un
                    canale di comunicazione aggiuntivo a quelli già esistenti.
                </li>
                <li>
                    In qualsiasi momento, posso interrompere l'utilizzo dei siti web e/o disinstallare le App e smettere di
                    utilizzarle.
                </li>
                <li>
                    L'uso dei miei dispositivi personali è soggetto alle politiche applicabili di Bunge, tra cui, ma non solo, la
                    politica "Bring Your Own Device" ("BYOD").
                </li>
                <li>
                    I costi di acquisizione, utilizzo, manutenzione, riparazione e/o sostituzione del mio dispositivo personale,
                    compreso il costo dei piani dati, non saranno rimborsati da Bunge, ma saranno a mio carico.
                </li>
                <li>
                    Bunge non mi richiede di consultare i siti web e/o le applicazioni al di fuori dell'orario di lavoro e non avrò
                    diritto a straordinari o a retribuzioni aggiuntive se accedo ai siti web e/o alle app al di fuori dell'orario di
                    lavoro.
                </li>
                <li>
                    Le password per i siti web e/o le applicazioni non sono trasferibili e devono essere adeguatamente protette
                    dalla divulgazione.
                </li>
                <li>
                    È vietato ad altre persone utilizzare i siti web e/o le applicazioni per mio conto o accedere a qualsiasi codice
                    di autenticazione inviato al mio numero di dispositivo mobile.
                </li>
                <li>
                    Devo rispettare la riservatezza di tutte le informazioni disponibili o ricevute tramite i siti web e/o le App,
                    comprese le informazioni riservate inviate al mio numero di dispositivo mobile.
                    Non posso divulgare e/o utilizzare tali informazioni per scopi diversi da quelli autorizzati da Bunge in base al
                    Codice di condotta di Bunge ed alle politiche e procedure di Bunge.
                </li>
                <li>
                    Accetto di informare tempestivamente Bunge di qualsiasi modifica apportata al numero di dispositivo
                    mobile riportato in questo modulo, nella misura in cui desidero ancora accedere ai siti web e/o alle
                    applicazioni.
                </li>
                <li>
                    Se il mio dispositivo viene smarrito o rubato, o altrimenti dismesso, informerò immediatamente Bunge
                    ed accetto che prima di dismettere il dispositivo, rimuoverò tutte le applicazioni Bunge che ho installato.
                </li>
                <li>
                    Accetto di informare immediatamente Bunge se vengo a conoscenza di un accesso non autorizzato ai siti
                    web e/o alle App o aelle mie credenziali utilizzate per l'accesso.
                </li>
                <li>
                    In caso di uso improprio, ed al più tardi alla data in cui non sarò più dipendente di Bunge, il mio accesso ai
                    siti web ed alle App sarà disattivato.
                </li>
                <li>
                    Riconosco che tutti i contenuti delle Applicazioni appartengono o sono concessi in licenza a Bunge e sono
                    protetti dalle leggi sul copyright e mi impegno a non utilizzare tali contenuti al di fuori delle App, o dopo la fine
                    del mio rapporto di lavoro, indipendentemente dallo scopo, a meno che non sia consentito da Bunge.
                </li>
                <li>
                    Il presente modulo di accesso è disciplinato dalle leggi sostanziali e procedurali applicabili nel paese in cui
                    sono impiegato.
                </li>
                <li>
                    Bunge non può essere ritenuta responsabile per un uso improprio delle mie credenziali se non ho applicato
                    un'adeguata protezione dalla divulgazione, se ho consegnato le mie credenziali ad altre persone non
                    autorizzate o se non ho denunciato immediatamente a Bunge lo smarrimento o il furto di un dispositivo.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Protezione dei dati personali
            </p>

            <p>
                Con la presente vengo informato che i miei dati personali trattati attraverso i siti web e/o le App sono trattati
                in conformità con la [Informativa sulla protezione dei dati dei dipendenti di Bunge Europe – Link] e con le leggi
                applicabili. Bunge tratterà i miei dati personali ai soli fini dell'invio dei codici relativi all'autenticazione a due
                fattori e di altre informazioni relative all'accesso, al numero del mio dispositivo mobile indicato di seguito, in
                modo che io possa accedere, attraverso il mio dispositivo mobile ai siti web e/o alle applicazioni Bunge di volta
                in volta su uno o più dei miei dispositivi personali e/o dei dispositivi Bunge.
                Il mio numero di telefono privato fornito di seguito verrà cancellato da Bunge entro 5 (cinque) giorni dalla data
                in cui smetterò di utilizzare il sistema o il mio accesso sarà disattivato da Bunge.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>