<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            USE TERMS AND CONDITIONS FOR BUNGE´S WEBSITESAND APPS
        </p>

        <p class="mt-6 mb-4">
            By voluntarily registering below to the websites and/or Apps of Bunge Limited and/or to its
            affiliates (“Bunge”), I acknowledge that I have read and understood the following terms and
            conditions of use, and by accepting the below button to register, I confirm that I agree with the
            following conditions:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Access to Bunge websites and/or Apps, including through a personal device, is voluntary,
                    optional and aims to strengthen Bunge's communication with its employees, being an
                    additional communication channel to the existing ones.
                </li>
                <li>
                    At any time, I can stop using the websites and/or uninstall the Apps and stop using them.
                </li>
                <li>
                    Use of my personal device(s)is subject to the applicable policies of Bunge, including but
                    not limited to the Bring Your Own Device Policy (“BYOD”).
                </li>
                <li>
                    The costs of acquisition, use, maintenance, repair and/or change of my personal device,
                    including the cost of data plans, will not be reimbursed by Bunge, but will be at my
                    expense.
                </li>
                <li>
                    Bunge does not require me to consult the websites and/or Apps outside of working
                    hours, and I will not be entitled to any overtime or additional pay if I access the websites
                    and/or Apps outside of working hours.
                </li>
                <li>
                    Passwords to the websites and/or Apps are non-transferable and should be
                    appropriately protected from disclosure.
                </li>
                <li>
                    Other persons are prohibited from using the websites and/or the Apps on my behalf or
                    accessing any authentication codes sent to my mobile device number.
                </li>
                <li>
                    I must respect the confidentiality of all the information available in or received via the
                    websites and/or Apps, including any confidential information sent to my mobile device.
                    I may not disclose and/or use that information for any purpose other than as authorized
                    by Bunge according to Bunge´s Code of Conduct and Bunge´s policies and procedures,
                    which I hereby acknowledge and agree to comply with..
                </li>
                <li>
                    I agree to inform Bunge promptly of any change I make to the mobile device number
                    reported in this form to the extent I still wish to access the websites and/or Apps.
                </li>
                <li>
                    If my device is lost or stolen, or otherwise disposed of, I will immediately inform Bunge
                    and agree that prior to disposition of any device, I will remove any Bunge Apps that I
                    have installed.
                </li>
                <li>
                    I agree to immediately inform Bunge if I become aware of any unauthorized access to
                    the websites and/or Apps or my credentials used for access.
                </li>
                <li>
                    In case of any misuse, and at the latest on the date when I am no longer employed by
                    Bunge, my access to the websites and Apps will be deactivated.
                </li>
                <li>
                    I am hereby informed that my personal data processed through the websites and/or the
                    Apps is processed in accordance with the Bunge Gida Sanayi ve Ticaret A.Ş. Information
                    Text on the Protection of Personal Data for Employees and laws governing my country
                    of employment.
                </li>
                <li>
                    I acknowledge that all content in the Apps belongs to or is licensed to Bunge and is
                    Protected by copyright laws, and I agree not to use any such content outside of the Apps,
                    or after my employment ends, regardless of the purpose unless allowed by Bunge.
                </li>
                <li>
                    This access form is governed by the applicable substantive and procedural laws of the
                    country where I am employed.
                </li>
                <li>
                    Bunge may not be held liable for a misuse of my credentials if I did not apply appropriate
                    protection from disclosure, handed over my credentials to other unauthorized persons
                    or did not report a lost or stolen device immediately to Bunge.
                </li>
            </ol>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>