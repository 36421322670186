<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            ПРАВИЛА ТА УМОВИ ВИКОРИСТАННЯ ДЛЯ HR ТА КОМУНІКАЦІЙНИХ ВЕБ-САЙТІВ І ДОДАТКІВ BUNGE
        </p>

        <p>
            Добровільно реєструючись нижче на веб-сайтах відділу персоналу та комунікацій та/або в
            додатках Bunge Global SA та/або в її афілійованих компаніях («Bunge»), я підтверджую, що
            прочитав та зрозумів наведені нижче положення та умови використання і погоджуюся їх
            дотримуватися:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Доступ до веб-сайтів та/або Додатків Bunge, у тому числі через особистий пристрій, є
                    добровільним, необов'язковим та спрямований на посилення комунікації Bunge зі своїми
                    співробітниками, будучи додатковим каналом зв'язку до існуючих, а також на надання
                    доступу до певних цифрових послуг та інформації співробітникам Bunge, зокрема тим,
                    кому не надаються пристрої компанії та адреси електронної пошти.
                </li>
                <li>
                    У будь-який час я можу припинити використання веб-сайтів та/або видалити Додатки та
                    припинити їх використання.
                </li>
                <li>
                    Використання мого особистого пристрою (пристроїв) регулюється чинною політикою
                    Bunge.
                </li>
                <li>
                    Я буду використовувати свою приватну адресу електронної пошти для доступу до веб
                    сайтів та/або додатків Bunge лише в тому випадку, якщо мені не було надано робочу
                    адресу електронної пошти. Я розумію, що використання моєї корпоративної адреси
                    електронної пошти є обов'язковим, якщо мені було надано таку адресу електронної
                    пошти.
                </li>
                <li>
                    Витрати, пов'язані з придбанням, використанням, технічним обслуговуванням, ремонтом
                    та/або зміною мого особистого пристрою, включаючи вартість тарифних планів, не
                    відшкодовуються компанією Bunge, а здійснюються за мій рахунок.
                </li>
                <li>
                    Bunge не вимагає від мене ознайомлення з веб-сайтами та/або Додатками в неробочий
                    час, і я не маю права на будь-яку понаднормову роботу або додаткову оплату, якщо я
                    отримую доступ до веб-сайтів та/або Додатків у неробочий час.
                </li>
                <li>
                    Паролі до веб-сайтів та/або Додатків не підлягають передачі та мають бути належним
                    чином захищені від розголошення.
                </li>
                <li>
                    Іншим особам заборонено використовувати веб-сайти та/або Додатки від мого імені або
                    отримувати доступ до будь-яких кодів автентифікації, надісланих на мою адресу
                    електронної пошти.
                </li>
                <li>
                    Я повинен поважати конфіденційність усієї інформації, доступної або отриманої через
                    веб-сайти та/або Додатки, включаючи будь-яку конфіденційну інформацію, надіслану на
                    мою адресу електронної пошти та/або мобільний пристрій. Я не можу розголошувати
                    та/або використовувати цю інформацію для будь-яких цілей, крім тих, які дозволені
                    Bunge відповідно до Кодексу поведінки Bunge та політики та процедур Bunge.
                </li>
                <li>
                    Я погоджуюся негайно інформувати Bunge про будь-які зміни, які я вношу в адресу
                    електронної пошти та/або номер мобільного пристрою, зазначені в цій формі, в тій мірі,
                    в якій я все ще хочу отримати доступ до веб-сайтів та/або Додатків.
                </li>
                <li>
                    Якщо мій пристрій буде втрачено, вкрадено або іншим чином утилізовано, я негайно
                    повідомлю про це Bunge і погоджуся, що перед утилізацією будь-якого пристрою я
                    видалю всі встановлені мною додатки Bunge.
                </li>
                <li>
                    Я погоджуюся негайно повідомити Bunge, якщо мені стане відомо про будь -який
                    несанкціонований доступ до веб-сайтів та/або Додатків або мої облікові дані, які
                    використовуються для доступу.
                </li>
                <li>
                    У разі будь-якого неправомірного використання, а також не пізніше дати, коли я більше
                    не працюю в Bunge, мій доступ до веб-сайтів і додатків буде деактивовано.
                </li>
                <li>
                    Я визнаю, що весь контент у Додатках належить або ліцензований Bunge і захищений
                    законами про авторське право, і я погоджуюся не використовувати будь-який такий
                    контент за межами Додатків або після закінчення моєї роботи, незалежно від мети, якщо
                    це не дозволено Bunge.
                </li>
                <li>
                    Ця форма доступу регулюється чинним матеріальним і процесуальним законодавством
                    країни, де я працюю.
                </li>
                <li>
                    Bunge не може бути притягнута до відповідальності за неправомірне використання моїх
                    облікових даних, якщо я не застосував належний захист від розголошення, не передав
                    свої облікові дані іншим стороннім особам або не повідомив Bunge негайно про втрату
                    або крадіжку пристрою.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Захист персональних даних
            </p>

            <p>
                Цим я проінформований, що мої персональні дані, які обробляються через веб-сайти
                та/або Додатки, обробляються відповідно до Повідомлення про захист даних
                співробітників Bunge Europe та чинного законодавства. Bunge буде обробляти мої
                персональні дані на основі моєї згоди виключно з метою підписки, процесу реєстрації та
                управління обліковими даними (скидання пароля), а також іншої інформації, пов'язаної
                з доступом, на вказану мною адресу електронної пошти, щоб я міг час від часу мати
                доступ через свій мобільний пристрій до веб-сайтів та/або додатків Bunge на одному або
                декількох моїх персональних пристроях та/або пристроях Bunge.
            </p>

            <p>
                Я розумію, що забезпечується належна безпека персональних даних і що вони захищені
                від несанкціонованої або незаконної обробки, а також від випадкової втрати, знищення
                або пошкодження.
            </p>

            <p>
                Якщо у мене немає корпоративної адреси електронної пошти для доступу до веб-сайтів
                та/або Додатків Bunge з мого особистого пристрою, моя приватна адреса електронної
                пошти, надана для доступу до веб-сайтів та/або Додатків Bunge, буде видалена Bunge
                протягом 5 (п'яти) днів з дати, коли я припиняю користуватися системою та/або мій
                доступ деактивується Bunge. Моя особиста адреса електронної пошти буде негайно
                видалена після припинення трудових або договірних відносин.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>
