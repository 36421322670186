<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            BUNGE WEB SİTELERİ VE UYGULAMALARI İÇİN KULLANIM ŞARTLARI VE KOŞULLARI
        </p>

        <p class="mt-6 mb-4">
            Bunge Limited'in web sitelerine ve/veya Uygulamalarına ve/veya
            iştiraklerine ("Bunge") ilişkin aşağıdaki şartları okuduğumu ve anladığımı kabul ediyorum.
            Kullanım koşullarını ve kayıt olmak için aşağıdaki butondaki kabul etme tuşuna basarak, aşağıdaki koşulları kabul ettiğimi onaylıyorum:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Bunge web sitelerine ve/veya Uygulamalarına kişisel bir cihaz aracılığıyla da dahil olmak üzere erişim isteğe bağlıdır, opsiyoneldir.Bunge'nin çalışanlarıyla iletişimini güçlendirmeyi amaçlayan ve mevcut olanlara ek sunulan bir iletişim kanalıdır.
                </li>
                <li>
                    İstediğim zaman web sitelerini kullanmayı bırakabilir ve/veya Uygulamaları kaldırabilir ve kullanmayı bırakabilirim.
                </li>
                <li>
                    Kişisel cihaz(lar)ımın kullanımı, aşağıdakiler de dahil olmak üzere Bunge'nin geçerli politikalarına tabidir link] adresinde bulunan Kendi Cihazını Getir Politikası ("BYOD").
                </li>
                <li>
                    Kişisel cihazımın edinim, kullanım, bakım, onarım ve/veya değişim masrafları, veri planlarının maliyeti de dahil olmak üzere, Bunge tarafından geri ödenmeyecek olup, ancak benim tarafımdan karşılanacaktır.
                </li>
                <li>
                    Bunge, çalışma saatleri dışında web sitelerine ve/veya Uygulamalara başvurmamı gerektirmez. ve ve/veya mesai saatleri dışındaki uygulamalar ile web sitelerine erişirsem herhangi bir fazla mesai veya ek ücret alma hakkım olmayacaktır.
                </li>
                <li>
                    Web sitelerinin ve/veya Uygulamaların şifreleri devredilemez ve ifşadan uygun şekilde korunur.
                </li>
                <li>
                    Diğer kişilerin web sitelerini ve/veya Uygulamaları benim adıma kullanması veya mobil cihaz numarama gönderilen kimlik doğrulama kodlarına erişmesi yasaktır
                </li>
                <li>
                    Mobil cihazıma gönderilen gizli bilgiler de dahil olmak üzere Mobil cihazımda bulunan veya mobil cihazım aracılığıyla alınan tüm bilgilerin gizliliğine saygı göstermem gerekmektedir. Işbu vesile ile onayladığım ve uymakla yükümlü olduğum Bunge'nin Mesleki Ahlak Kuralları ve Bunge'nin politika ve prosedürlerine göre, Bunge’nin yetkilendirdiği bilgiler dışında bilgi kullanmayacağım ve ifşa etmeyeceğim.
                </li>
                <li>
                    Web sitelerine ve/veya Uygulamalara erişmek istediğim ölçüde bu formda bildirilen mobil cihaz numaramda yaptığım herhangi bir değişikliği derhal Bunge'ye bildirmeyi kabul ediyorum.
                </li>
                <li>
                    Cihazımın kaybolması veya çalınması ya da başka bir şekilde elden çıkarılması durumunda Bunge'yi derhal bilgilendireceğim ve herhangi bir cihazın elden çıkarılmadan önce, yüklediğim tüm Bunge Uygulamalarını kaldıracağımı kabul ediyorum.
                </li>
                <li>
                    Web siteleri ve/veya Uygulamalar veya erişim için kullanılan kimlik bilgilerim ile ilgili herhangi bir yetkisi verilmemiş erişimin farkına varırsam Bunge'yi derhal bilgilendirmeyi kabul ediyorum.
                </li>
                <li>
                    Herhangi bir kötüye kullanım durumunda ve Bunge’de en geç işten ayrıldığım tarih olarak Bunge, web sitelerine ve Uygulamalara erişimim devre dışı bırakılacaktır.
                </li>
                <li>
                    İşbu belge ile web siteleri ve/veya Uygulamalar aracılığıyla işlenen kişisel verilerimin, Bunge Gıda Sanayi ve Ticaret A.Ş Kişisel Verileri Koruma Metnine ile ülke istihdam kanunlarına uyumlu olarak işlendiğine ilişkin bilgilendirildim.
                </li>
                <li>
                    Uygulamalardaki tüm içeriğin Bunge'ye ait olduğunu veya Bunge’ye lisanslı olduğunu, aynı zamanda telif hakkı yasaları ile korunduğunu kabul ediyorum.  Bunge tarafından izin verilmediği müddetçe sebebi ne olursa olsun iş sözleşmem sona erdikten sonra bu tür içerikleri kullanmamayı kabul ediyorum,
                </li>
                <li>
                    Bu erişim formu, istihdam edildiğim ülkedeki uygulanan prosedürsel ve temel kanunlar ile belirlenmiştir.
                </li>
                <li>
                    Bunge’ye derhal bildirilmeyen kayıp veya çalıntı cihaz raporlaması yapılmadığında, kimlik bilgileri yetkisi olmayan biriyle paylaşıldığında ve ifşadan koruma için uygun bilgiler paylaşılmadığında, kimlik bilgilerinin kötüye kullanılmasından ötürü Bunge sorumlu tutulamaz.
                </li>
            </ol>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>