<template>
    <div class="legal-policy-wrapper">

        <p>
            Dear Colleague,
        </p>

        <p>
            We understand that you are using or have requested to use your personal devices to access
            Bunge’s systems, platform, network and/or software (collectively, “Bunge Systems”).
        </p>

        <p>
            The use of personal devices for business purposes gives rise to increased risk in terms of
            the security of Bunge’s communication systems, technology assets and resources, the protection
            of confidential information, reputation of Bunge and compliance with legal obligations.
            In order to protect you and Bunge from such risks, the following terms and conditions shall
            apply to your use of the personal devices and Bunge will be processing your personal mobile
            device number and/or personal email address (collectively, “Personal Data”) to send you,
            where applicable, a Multi-Factor Authentication code for verification purposes (“MFA Verification”):
        </p>

        <p class="mt-6 mb-4 font-weight-bold">
            Terms and Conditions
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Access to Bunge Systems, including through a personal device, is voluntary, optional and aims at strengthening Bunge's communication with its employees, being an additional communication channel to the existing ones, and to provide access to certain digital services and information to Bunge’s employees, in particular those who are not provided with company devices and/or company email addresses.
                </li>
                <li>
                    At any time, you can stop using your personal device(s) to access the Bunge Systems, by giving written notice to Bunge.
                </li>
                <li>
                    Use of your personal device(s) is subject to the applicable policies and procedures of Bunge, as enacted from time to time and you are required to comply with them.
                </li>
                <li>
                    You should only use your private email address to access Bunge Systems if you are not provided with a company email address. If you are provided a company email address, the use of that company email address to access Bunge Systems is mandatory.
                </li>
                <li>
                    You should only use your personal mobile device number to access Bunge Systems if you are not provided with a company mobile phone number. If you are provided a company mobile phone number, the use of that company mobile phone number to access Bunge Systems is mandatory.
                </li>
                <li>
                    The costs of acquisition, use, maintenance, repair and/or change of your personal device(s), including the cost of data plans is subject to your applicable local policies and procedures of Bunge, as enacted from time to time.
                </li>
                <li>
                    Bunge does not require you to consult the Bunge Systems outside of working hours, and you will not be entitled to any overtime or additional pay if you access Bunge Systems outside of working hours.
                </li>
                <li>
                    Passwords to the Bunge Systems are non-transferable and should be appropriately protected from disclosure.
                </li>
                <li>
                    Other persons are prohibited from using the Bunge Systems on your behalf or accessing any authentication codes sent to your personal email address and/or mobile device number.
                </li>
                <li>
                    You must respect the confidentiality of all the information available in or received via the Bunge Systems, including any confidential information sent to your personal email address and/or mobile device. You may not disclose and/or use that information for any purpose other than as authorized by Bunge according to Bunge´s Code of Conduct and Bunge´s policies and procedures, which you hereby acknowledge and agree to comply with.
                </li>
                <li>
                    You agree to inform Bunge promptly of any change you make to the personal email address and/or mobile device number previously notified to Bunge to the extent you still wish to access the Bunge Systems.
                </li>
                <li>
                    If your device is lost or stolen, or otherwise disposed of, you will immediately inform Bunge and agree that prior to disposition of any device, you will remove any Bunge Systems that you have installed.
                </li>
                <li>
                    You agree to immediately inform Bunge if you become aware of any unauthorized access to the Bunge Systems or your credentials used for access.
                </li>
                <li>
                    In case of any misuse, and at the latest on the date when you are no longer employed by Bunge, your access to the Bunge Systems will be deactivated and removed without further or prior notice, you agree that, if required, authorized personnel of Bunge shall have the right to access the Bunge Systems on your personal device(s) and remove access to the Bunge Systems.
                </li>
                <li>
                    Your personal data processed through the Bunge Systems is processed in accordance with the Bunge data protection and privacy policies and applicable laws. Bunge will process your personal data for the sole purposes of subscription, enrollment process and management of credentials (password reset), so that you may have access, through the MFA Verification sent to your personal email address and/or personal mobile device number, to the Bunge Systems from time to time.
                </li>
                <li>
                    You acknowledge that all contents in the Bunge Systems belong to or are licensed to Bunge and is protected by intellectual property laws, and you agree not to use any such contents outside of the Bunge Systems, regardless of the purpose unless expressly allowed by Bunge.
                </li>
                <li>
                    These terms and conditions are governed by the same law and dispute resolution clause governing your employment contract with Bunge.
                </li>
            </ol>

            <p class="mt-6">
                If you would like to use or continue to use your personal device(s) to access the Bunge Systems, please click at the link below.
            </p>

            <p>
                By doing so, you confirm that you have read, understood, and agreed with the Terms and Conditions and agree for your Personal Data to be processed in accordance with the Terms and Conditions. You further acknowledge that your confirmation (although given electronically) constitutes an unequivocal manifestation of your acceptance, being valid and enforceable for any purpose.
            </p>

            <p>
                Should you have further questions please contact the Australia IT Team and the SEA Legal Team at sea.legal@bunge.com
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>