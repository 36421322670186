<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            TERMENI ȘI CONDIȚII DE UTILIZARE A SITE-URILOR ȘI APLICAȚIILOR BUNGE
        </p>

        <p class="mt-6 mb-4">
            Prin înregistrarea voluntară de mai jos pe site-urile web și/sau aplicațiile Bunge Limited și/sau pe site-urile sale
            afiliați ("Bunge"), recunosc că am citit și am înțeles următorii termeni șicondiții de utilizare și sunt de acord să le respect:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Accesul la site-urile web și/sau la aplicațiile Bunge, inclusiv prin intermediul unui dispozitiv personal, este voluntar,opțional și are ca scop consolidarea comunicării Bunge cu angajații săi, fiind un
                    canal de comunicare suplimentar la cele existente.
                </li>
                <li>
                    În orice moment, pot să renunțla folosirea site-urilor web și/sau să dezinstalez aplicațiile și să nu le mai folosesc.
                </li>
                <li>
                    Utilizarea dispozitivului (dispozitivelor) meu (mele) personal(e) este supusă politicilor aplicabile ale Bunge, inclusiv, dar
                    dar fără a se limita la Politica de utilizare a dispozitivelor personale ("BYOD").
                </li>
                <li>
                    Costurile de achiziție, utilizare, întreținere, reparare și/sau schimbare a dispozitivului meu personal,
                    inclusiv costul planurilor de date, nu vor fi rambursate de către Bunge, ci vor fi suportate de catre mine.
                </li>
                <li>
                    Bunge nu îmi impune să consult site-urile și/sau aplicațiile în afara programului de lucru
                    orele de lucru, iar eu nu voi avea dreptul la ore suplimentare sau la o plată suplimentară dacă accesez site-urile web
                    și/sau aplicațiile în afara orelor de lucru.
                </li>
                <li>
                    Parolele de acces la site-urile web și/sau la aplicații sunt netransferabile și ar trebui să fie
                    protejate în mod corespunzător împotriva divulgării.
                </li>
                <li>
                    Se interzice altor persoane să utilizeze site-urile web și/sau aplicațiile în numele meu sau
                    accesarea oricăror coduri de autentificare trimise la numărul meu de dispozitiv mobil.
                </li>
                <li>
                    Trebuie să respect confidențialitatea tuturor informațiilor disponibile în sau primite prin intermediul
                    site-urilor web și/sau a aplicatiilor, inclusiv orice informație confidențială trimisă către dispozitivul meu mobil.
                    Nu trebuie să dezvălui și/sau să folosesc aceste informații în alte scopuri decât cele autorizate
                    de către Bunge, în conformitate cu Codul de conduită Bunge și cu politicile și procedurile Bunge.
                </li>
                <li>
                    Sunt de acord să informez Bunge cu promptitudine cu privire la orice modificare pe care o fac la numărul dispozitivului mobil
                    raportat în acest formular, în măsura în care doresc în continuare să accesez site-urile web și/sau aplicațiile.
                </li>
                <li>
                    În cazul în care dispozitivul meu este pierdut sau furat, sau dacă se dispune de el în alt mod, voi informa imediat Bunge
                    și sunt de acord că, înainte de a dispune de orice dispozitiv, voi elimina toate aplicațiile Bunge pe care le-am instalat.
                </li>
                <li>
                    Sunt de acord să informez imediat Bunge dacă iau cunoștință de orice acces neautorizat la
                    site-urile web și/sau aplicațiile sau la acreditările mele folosite pentru acces.
                </li>
                <li>
                    În cazul oricărei utilizări abuzive și cel târziu la data la care nu mai sunt angajat de către
                    Bunge, accesul meu la site-urile web și la aplicații va fi dezactivat.
                </li>
                <li>
                    Recunosc că tot conținutul din aplicatii aparține sau este licențiat de Bunge și este
                    protejat de legile privind drepturile de autor și sunt de acord să nu folosesc niciun astfel de conținut în afara aplicațiilor,
                    sau după ce îmi încetează contractul de muncă, indiferent de scop, cu excepția cazului în care Bunge îmi permite acest lucru.
                </li>
                <li>
                    Acest formular de acces este guvernat de legile substanțiale și procedurale aplicabile ale
                    țării în care sunt angajat.
                </li>
                <li>
                    Bunge nu poate fi trasă la răspundere pentru o utilizare necorespunzătoare a acreditărilor mele dacă nu am aplicat procedurile corespunzătoare de
                    protecție împotriva divulgării, am înmânat credențialele  mele altor persoane neautorizate
                    sau nu am raportat imediat la Bunge un dispozitiv pierdut sau furat.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Protecția datelor cu caracter personal
            </p>

            <p>
                Sunt informat prin prezenta că datele mele personale prelucrate prin intermediul site-urilor web și/sau al
                aplicațiilor sunt prelucrate în conformitate cu [Notificarea privind protecția datelor cu caracter personal – link] și cu legislatia in vigoare
            </p>

            <p>
                Bunge va prelucra datele mele cu caracter personal în scopul exclusiv de a trimite coduri legate de autentificarea cu doi factori și alte informații
                legate de acces, la numărul dispozitivului meu mobil stabilit mai jos, astfel încât să pot avea acces
                prin intermediul dispozitivului meu mobil la site-urile web și/sau aplicațiile Bunge din când în când pe unul sau
                mai multe dintre dispozitivele mele personale și/sau dispozitivele Bunge.
            </p>

            <p>
                Numărul meu de telefon privat furnizat mai jos va fi șters de Bunge în termen de 5 (cinci)
                zile de la data la care încetez să mai folosesc sistemul și/ sau accesul meu este dezactivat
                de către Bunge.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>