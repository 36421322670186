<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            TERMS OF US OF BUNGE´S WEBSITES AND APPS
        </p>

        <p>
            The undersigned employee of Bunge Limited and/or one of its subsidiaries (“Bunge”),
            voluntarily requests and gives permission to Bunge to send codes related to two-factor authentication,
            and other information related to access, to my email address and/or mobile device number set forth below
            so that I may have access through my email and/or mobile device to Bunge websites and/or Apps from time to
            time on one or more of my personal devices and/or Bunge devices.
        </p>

        <p class="mt-6 mb-4">
            By signing below, I confirm I have read, understood and agreed  with the following conditions:
        </p>

        <v-divider></v-divider>

        <ol class="legal-policy-content">
            <li>
                Access to Bunge  websites  and/or  Apps, including through a personal device, is voluntary, optional
                and aims to strengthen Bunge's communication with its employees, being an additional communication
                channel to the existing ones.
            </li>
            <li>
                At any time, I can stop using the websites and/or uninstall the Apps and stop using them.
            </li>
            <li>
                Use of my personal device(s) is subject to the applicable policies of Bunge, including but not limited
                to a Bring Your Own Device Policy (“BYOD”) if I have been informed that the BYOD policy applies to my use.
            </li>
            <li>
                The costs of acquisition, use, maintenance, repair and/or change of my personal device, including the
                cost of data plans, will not be reimbursed by Bunge, but will be at my expense.
            </li>
            <li>
                Passwords to the websites and/or Apps are non-transferable and should be appropriately protected from disclosure.
            </li>
            <li>
                Other persons are  prohibited from using the websites and/or the Apps on my behalf, or accessing any
                authentication codes sent to my email address and/or mobile device number.
            </li>
            <li>
                I must respect the confidentiality of all the information available in or received via the websites
                and/or Apps, including any confidential information sent to my email address and/or mobile device.
                I may not disclose and/or use that information for any  purpose other than  as authorized by Bunge
                according to Bunge´s Code of Conduct and Bunge´s policies and procedures, which I hereby acknowledge
                and agree to comply with.
            </li>
            <li>
                I accept the use of the websites and/or Apps to digitally sign the documents related to my employment
                with Bunge.
            </li>
            <li>
                I acknowledge that my digital signature constitutes an unequivocal manifestation of my acceptance,
                being valid and enforceable for any purpose.
            </li>
            <li>
                I agree to inform Bunge promptly of any change I make to the email address and/or mobile device number
                reported in this form to the extent I still wish to access the websites and/or Apps.
            </li>
            <li>
                If my device is lost or stolen, or otherwise disposed of, I will immediately inform Bunge and agree
                that prior to disposition of any device, I will remove any Bunge Apps that I have installed.
            </li>
            <li>
                I agree to immediately inform Bunge if I become aware of any unauthorized access to the websites and/or
                Apps or my credentials used for access.
            </li>
            <li>
                In case of any misuse, and at the latest on the date when I am no longer employed by Bunge, my access
                to the websites and Apps will be deactivated.
            </li>
            <li>
                I am hereby informed that my personal data processed through the websites and/or the Apps is processed
                in accordance with the applicable data protection notice and laws governing my country of employment.
            </li>
            <li>
                I acknowledge that all content in the Apps belongs to or is licensed to Bunge and is protected by
                copyright laws, and I agree not to use any such content outside of the Apps, regardless of the purpose
                unless allowed by Bunge.
            </li>
            <li>
                This access form is governed by the internal substantive and procedural applicable laws where I am employed,
                with competent jurisdiction.
            </li>
            <li>
                Once I resign from services of Bunge, in whatever capacity I have been working with, I shall not be entitled to use,
                retain, forward and otherwise  use in any manner whatsoever the data which I might have accessed/downloaded.
                Any use of such data shall be construed as unauthorized access and I shall be legally bound to remain
                accountable for all such unauthorized use through me and the device which I might have used in past.
            </li>
        </ol>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>