<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            USE TERMS AND CONDITIONS FOR BUNGE´S WEBSITESAND APPS
        </p>

        <p class="mt-6 mb-4">
            By voluntary registering below to the websites and/or Apps of Bunge Limited and/or to its
            affiliates (“Bunge”), I acknowledge that I have read and understood the following terms and
            conditions of use and I agree to comply with them:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Access to Bunge websites and/or Apps, including through a personal device, is voluntary,
                    optional and aims to strengthen Bunge's communication with its employees, being an
                    additional communication channel to the existing ones.
                </li>
                <li>
                    At any time, I can stop using the websites and/or uninstall the Apps and stop using them.
                </li>
                <li>
                    Use of my personal device(s)is subject to the applicable policies of Bunge, including but
                    not limited to the Bring Your Own Device Policy (“BYOD”).
                </li>
                <li>
                    Bunge does not require me to consult the websites and/or Apps outside of working
                    hours, and I will not be entitled to any overtime or additional pay if I access the websites
                    and/or Apps outside of working hours.
                </li>
                <li>
                    Passwords to the websites and/or Apps are non-transferable and should be
                    appropriately protected from disclosure.
                </li>
                <li>
                    Other persons are prohibited from using the websites and/or the Apps on my behalf or
                    accessing any authentication codes sent to my mobile device number.
                </li>
                <li>
                    I must respect the confidentiality of all the information available in or received via the
                    websites and/or Apps, including any confidential information sent to my mobile device.
                    I may not disclose and/or use that information for any purpose other than as authorized
                    by Bunge according to Bunge´s Code of Conduct and Bunge´s policies and procedures.
                </li>
                <li>
                    I agree to inform Bunge promptly of any change I make to the mobile device number
                    reported in this form to the extent I still wish to access the websites and/or Apps.
                </li>
                <li>
                    If my device is lost or stolen, or otherwise disposed of, I will immediately inform Bunge
                    and agree that prior to disposition of any device, I will remove any Bunge Apps that I
                    have installed.
                </li>
                <li>
                    I agree to immediately inform Bunge if I become aware of any unauthorized access to
                    the websites and/or Apps or my credentials used for access.
                </li>
                <li>
                    In case of any misuse, and at the latest on the date when I am no longer employed by
                    Bunge, my access to the websites and Apps will be deactivated.
                </li>
                <li>
                    I acknowledge that all content in the Apps belongs to or is licensed to Bunge and is
                    Protected by copyright laws, and I agree not to use any such content outside of the Apps,
                    or after my employment ends, regardless of the purpose unless allowed by Bunge.
                </li>
                <li>
                    This access form is governed by the applicable substantive and procedural laws of the
                    country where I am employed.
                </li>
                <li>
                    Bunge may not be held liable for a misuse of my credentials if I did not apply appropriate
                    protection from disclosure, handed over my credentials to other unauthorized persons
                    or did not report a lost or stolen device immediately to Bunge.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Personal Data Protection
            </p>

            <p>
                I am hereby informed that my personal data processed through the websites and/or the Apps is
                processed in accordance with Bunge´s policies and the applicable laws. Bunge will process my
                personal data for the sole purposes of sending codes related to two-factor authentication, and
                other information related to access, to my mobile device number set forth below so that I may
                have access through my mobile device to Bunge websites and/or Apps from time to time on one
                or more of my personal devices and/or Bunge devices.
            </p>

            <p>
                My private phone number provided hereunder will be deleted by Bunge within 5 (five) days as
                of the date on which I stop to use the system and or my access is deactivated by Bunge.
            </p>

            <p class="mt-10 mb-4 font-weight-bold">
                Data Transmission Across the Border
            </p>

            <p>
                I am fully aware and agree with the followings:
            </p>

            <p>
                As Bunge is a multinational company, when the Company is performing its obligations to me,
                or when I am performing my job functions, or for reasons described in this Terms and Conditions,
                my personal information mentioned in this Terms and Conditions may be transmitted to Bunge
                headquarters in the St. Louis Missouri outside China.
            </p>

            <p>
                I will inform the Company about change in my personal data to ensure that the data is timely updated.
                I can contact with HR division to request for accessing, correcting and deleting my personal data,
                as well as to inform change in my personal data.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>