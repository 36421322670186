<template>
    <div class="legal-policy-wrapper">

        <p>
            Rakan sekerja yang dihormati,
        </p>

        <p>
            Kami memahami bahawa anda sedang menggunakan atau telah meminta untuk menggunakan peranti peribadi anda untuk mengakses sistem, platform, rangkaian dan/atau perisian Bunge (secara kolektif, "Sistem Bunge").
        </p>

        <p>
            Penggunaan peranti peribadi bagi tujuan perniagaan meningkatkan risiko dari segi keselamatan sistem komunikasi, aset teknologi dan sumber-sumber Bunge, perlindungan maklumat sulit, reputasi Bunge dan pematuhan tanggungjawab undang-undang. Bagi melindungi anda dan Bunge daripada risiko-risiko sedemikian, penggunaan peranti peribadi anda adalah tertakluk kepada terma dan syarat berikut, dan Bunge akan memproses nombor peranti mudah alih peribadi anda dan/atau alamat e-mel peribadi anda (secara kolektif, “Data Peribadi”) bagi menghantar kepada anda, jika berkenaan, kod Pengesahan Berbilang Faktor untuk tujuan pengesahan (“Pengesahan MFA”).
        </p>

        <p class="mt-6 mb-4 font-weight-bold">
            Terma dan Syarat
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>Akses kepada Sistem Bunge, termasuk melalui peranti peribadi, merupakan sejenis komunikasi secara sukarela, secara pilihan dan bertujuan untuk mengukuhkan komunikasi Bunge dengan pekerja-perkerjanya, sebagai satu saluran komunikasi tambahan kepada komunikasi yang sedia ada, dan untuk memberi akses kepada perkhidmatan dan maklumat digital tertentu kepada pekerja-pekerja Bunge, khususnya kepada pekerja-perkerja yang tidak diberikan peranti syarikat dan/atau alamat e-mel syarikat.
                </li>
                <li>
                    Anda boleh berhenti menggunakan peranti peribadi anda untuk mengakses Sistem Bunge pada bila-bila masa, dengan memberikan notis secara bertulis kepada Bunge.
                    Penggunaan peranti peribadi anda adalah tertakluk kepada dasar-dasar dan prosedur-prosedur Bunge yang berkenaan, seperti yang digubal dari semasa ke semasa dan anda dikehendaki mematuhinya.
                </li>
                <li>
                    Anda harus hanya menggunakan alamat e-mel peribadi anda untuk mengakses Sistem Bunge jika anda tidak diberikan alamat e-mel syarikat. Jika anda diberikan alamat e-mel syarikat, maka penggunaan alamat e-mel syarikat itu untuk mengakses Sistem Bunge adalah wajib.
                </li>
                <li>
                    Anda harus hanya menggunakan nombor peranti mudah alih peribadi anda untuk mengakses Sistem Bunge jika anda tidak diberikan nombor telefon mudah alih syarikat. Jika anda diberikan nombor telefon mudah alih syarikat, maka penggunaan nombor telefon mudah alih syarikat itu untuk mengakses Sistem Bunge adalah wajib..
                </li>
                <li>
                    Kos pemerolehan, penggunaan, penyelenggaraan, pembaikan dan/atau penukaran peranti peribadi anda, termasuk kos pelan data adalah tertakluk kepada dasar-dasar dan prosedur-prosedur Bunge tempatan anda yang berkenaan, seperti yang digubal dari semasa ke semasa.
                </li>
                <li>
                    Bunge tidak memerlukan anda untuk mengguna Sistem Bunge di luar waktu kerja, dan anda tidak berhak mendapat sebarang bayaran lebih masa atau bayaran tambahan jika anda mengakses Sistem Bunge di luar waktu kerja.
                </li>
                <li>
                    Kata laluan kepada Sistem Bunge tidak boleh dipindah milik dan harus dilindungi sewajarnya daripada pendedahan.
                </li>
                <li>
                    Orang-orang lain adalah dilarang daripada menggunakan Sistem Bunge bagi pihak anda atau mengakses sebarang kod pengesahan yang dihantar ke alamat e-mel peribadi anda dan/atau nombor peranti mudah alih anda.
                </li>
                <li>
                    Anda mesti menghormati kesulitan semua maklumat yang sedia ada atau diterima melalui Sistem Bunge, termasuk sebarang maklumat sulit yang dihantar ke alamat e-mel peribadi anda dan/atau peranti mudah alih anda. Anda tidak boleh mendedahkan dan/atau menggunakan maklumat tersebut untuk sebarang tujuan selain daripada yang dibenarkan oleh Bunge mengikut Tatakelakuan Bunge dan dasar-dasar dan prosedur-prosedur Bunge, yang anda dengan ini mengakui dan bersetuju untuk mematuhinya.
                </li>
                <li>
                    Anda bersetuju untuk memaklumi Bunge dengan segera tentang sebarang perubahan yang anda buat terhadap alamat e-mel peribadi dan/atau nombor peranti mudah alih anda yang telah dimaklumkan kepada Bunge selagi anda masih ingin mengakses Sistem Bunge.
                </li>
                <li>
                    Jika peranti anda telah hilang atau dicuri, atau dilupuskan sebaliknya, anda akan segera memaklumi Bunge dan anda  bersetuju bahawa sebelum pelupusan mana-mana peranti, anda akan mengalih keluar mana-mana Sistem Bunge yang telah dipasangkan anda.
                </li>
                <li>
                    Anda bersetuju untuk segera memaklumi Bunge jika anda mengetahui sebarang akses tanpa kebenaran kepada Sistem Bunge atau kepada sebarang kelayakan akses yang digunakan anda.
                </li>
                <li>
                    Sekiranya berlaku sebarang penyalahgunaan, dan selewat-lewatnya pada tarikh anda tidak lagi digaji oleh Bunge, akses anda kepada Sistem Bunge akan dinyahaktifkan dan dialih keluar tanpa sebarang notis lanjut atau notis awal, Anda bersetuju bahawa, jika perlu, kakitangan yang diberi kuasa Bunge mempunyai hak untuk mengakses Sistem Bunge yang dipasang pada peranti peribadi anda dan mengalih keluar akses kepada Sistem Bunge.
                </li>
                <li>
                    Data peribadi anda yang diproses melalui Sistem Bunge adalah diproses mengikut perlindungan data Bunge dan dasar privasi serta undang-undang yang berkenaan. Bunge akan memproses data peribadi anda untuk tujuan langganan, proses pendaftaran dan pengurusan bukti kelayakan (pasang semula kata laluan), supaya anda boleh mempunyai akses kepada Sistem Bunge dari semasa ke semasa, melalui Pengesahan MFA yang dihantar ke alamat e-mel peribadi anda dan/atau nombor peranti mudah alih peribadi anda.
                </li>
                <li>
                    Anda mengakui bahawa semua kandungan dalam Sistem Bunge adalah hakmilik Bunge atau dilesenkan kepada Bunge dan dilindungi oleh undang-undang harta intelek, dan anda bersetuju untuk tidak menggunakan mana-mana kandungan tersebut di luar Sistem Bunge, tanpa mengira tujuan selain dibenarkan secara nyata oleh Bunge.
                </li>
                <li>
                    Sekiranya terdapat apa-apa percanggahan atau taksiran perbezaan antara versi Bahasa Melayu dan versi bahasa Inggeris, versi bahasa Inggeris akan digunakan dan peruntukan yang berkenaan bagi versi Bahasa Melayu akan ditafsirkan sewajarnya.
                </li>
                <li>
                    Terma dan syarat ini tertakluk kepada undang-undang dan klausa penyelesaian pertikaian yang sama dengan undang-undang dan klausa penyelesaian pertikaian yang kontrak pekerjaan anda dengan Bunge tertakluk.
                </li>
            </ol>

            <p class="mt-6">
                Jika anda ingin menggunakan atau terus menggunakan peranti peribadi anda untuk mengakses Sistem Bunge, sila klik pada pautan di bawah.
            </p>

            <p>
                Dengan berbuat demikian, anda mengesahkan bahawa anda telah membaca, memahami dan bersetuju dengan Terma dan Syarat serta bersetuju untuk Data Peribadi anda diproses mengikut Terma dan Syarat. Anda selanjutnya mengakui bahawa pengesahan anda (walaupun diberikan secara elektronik) merupakan manifestasi penerimaan anda yang tidak berbelah bahagi, sah dan boleh dikuatkuasakan untuk sebarang tujuan.
            </p>

            <p>
                Saya mengesahkan bahawa saya telah membaca, memahami dan bersetuju menerima Terma dan Syarat berkenaan dengan perkara di atas dan dengan ini *bagi maklumat berikut untuk Pengesahan MFA:
            </p>

            <p>
                alamat e-mel peribadi saya: ________________
            </p>

            <p>
                nombor peranti mudah alih peribadi saya: __________________
            </p>

            <p>
                *Sila tandakan dan berikan maklumat di atas yang berkenaan. Sila ambil perhatian bahawa:
            </p>

            <p>
                Anda harus hanya menggunakan alamat e-mel peribadi anda untuk mengakses Sistem Bunge  jika anda tidak diberikan alamat e-mel syarikat. Jika anda diberikan alamat e-mel syarikat, maka penggunaan alamat e-mel syarikat itu untuk mengakses Sistem Bunge adalah wajib.
            </p>

            <p>
                Anda harus hanya menggunakan nombor peranti mudah alih peribadi anda untuk mengakses Sistem Bunge jika anda tidak diberikan nombor telefon mudah alih syarikat. Jika anda diberikan nombor telefon mudah alih syarikat, maka penggunaan nombor telefon mudah alih  syarikat itu untuk mengakses Sistem Bunge adalah wajib.
            </p>

            <p>
                Sekiranya anda ada sebarang pertanyaan lanjut, sila hubungi Jabatan IT Malaysia di BEA.PSG.IT@bunge.com dan Jabatan Undang-undang di sea.legal@bunge.com
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>