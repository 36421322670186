<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            USE TERMS AND CONDITIONS FOR BUNGE´S HR AND COMMUNICATION WEBSITES AND APPS
        </p>

        <p>
            By voluntary registering below to the HR and communication websites and/or Apps of Bunge Global SA and/or to its affiliates (“Bunge”), I acknowledge that I have readand understoodthe following terms and conditions of use and I agree to comply with them:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    Access to Bunge websites and/or Apps, including through a personal device, is voluntary,
                    optional and aims at strengthening Bunge's communication with its employees, being an
                    additional communication channel to the existing ones, and to provide access to certain digital
                    services and information to Bunge’s employees, in particular those who are not provided with
                    company devices and email addresses.
                </li>
                <li>
                    At any time, I can stop using the websites and/or uninstall the Apps and stop using them.
                </li>
                <li>
                    Use of my personal device(s) is subject to the applicable policies of Bunge.
                </li>
                <li>
                    I will only use my private email address to access Bunge websites and/or Apps in case I was
                    not provided with a business email address. I understand that the use of my business email
                    address is mandatory if I was provided with such an email address.
                </li>
                <li>
                    The costs of acquisition, use, maintenance, repair and/or change of my personal device,
                    including the cost of data plans, will not be reimbursed by Bunge, but will be at my expense.
                </li>
                <li>
                    Bunge does not require me to consult the websites and/or Apps outside of working hours, and
                    I will not be entitled to any overtime or additional pay if I access the websites and/or Apps
                    outside of working hours.
                </li>
                <li>
                    Passwords to the websites and/or Apps are non-transferable and should be appropriately
                    protected from disclosure.
                </li>
                <li>
                    Other persons are prohibited from using the websites and/or the Apps on my behalf or
                    accessing any authentication codes sent to my email address.
                </li>
                <li>
                    I must respect the confidentiality of all the information available in or received via the websites
                    and/or Apps, including any confidential information sent to my email address and/or mobile
                    device. I may not disclose and/or use that information for any purpose other than as
                    authorized by Bunge according to Bunge´s Code of Conduct and Bunge´s policies and
                    procedures.
                </li>
                <li>
                    I agree to inform Bunge promptly of any change I make to the email address and/or mobile
                    device number reported in this form to the extent I still wish to access the websites and/or
                    Apps
                </li>
                <li>
                    If my device is lost or stolen, or otherwise disposed of, I will immediately inform Bunge and
                    agree that prior to disposition of any device, I will remove any Bunge Appsthat I have installed.
                </li>
                <li>
                    I agree to immediately inform Bunge if I become aware of any unauthorized access to the
                    websites and/or Apps or my credentials used for access.
                </li>
                <li>
                    In case of any misuse, and at the latest on the date when I am no longer employed by Bunge,
                    my access to the websites and Apps will be deactivated.
                </li>
                <li>
                    I acknowledge that all content in the Apps belongs to or is licensed to Bunge and is protected
                    by copyright laws, and I agree not to use any such content outside of the Apps, or after my
                    employment ends, regardless of the purpose unless allowed by Bunge.
                </li>
                <li>
                    This access form is governed by the applicable substantive and procedural laws of the country
                    where I am employed.
                </li>
                <li>
                    Bunge may not be held liable for a misuse of my credentials if I did not apply appropriate
                    protection from disclosure, handed over my credentials to other unauthorized persons or did
                    not report a lost or stolen device immediately to Bunge.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Personal Data Protection
            </p>

            <p>
                I am hereby informed that my personal data processed through the websites and/or the Apps
                is processed in accordance with the Bunge Europe Employee Data Protection Notice and the
                applicable laws. Bunge will process my personal data based on my consent for the sole
                purposes of subscription, enrollment process and management of credentials (password
                reset), and other information related to access, to the email address I provided so that I may
                have access through my mobile device to Bunge websites and/or Apps from time to time on
                one or more of my personal devices and/or Bunge devices.
            </p>

            <p>
                I understand that appropriate security of personal data is ensured and that they are protected
                against unauthorized or unlawful processing and against accidental loss, destruction or
                damage.
            </p>

            <p>
                If I do not have a business email address to access Bunge websites and/or Apps with my
                personal device, my private email address provided to access Bunge websites and/or Apps will
                be deleted by Bunge within 5 (five) days as of the date on which I stop to use the systemand/or
                my access is deactivated by Bunge. My personal email address will be immediately deleted
                upon termination of employment or contractual relationship.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>
