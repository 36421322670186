<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            A BUNGE WEBOLDALAINAK ÉS ALKALMAZÁSAINAK HASZNÁLATI FELTÉTELEI
        </p>

        <p class="mt-6 mb-4">
            A Bunge Limited és/vagy leányvállalatai (“Bunge”) weboldalaira és/vagy alkalmazásaira történő önkéntes regisztrációval elismerem, hogy elolvastam és megértettem az alábbi felhasználási feltételeket és elfogadom azok betartását:
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    A Bunge weboldalaihoz és/vagy alkalmazásaihoz való hozzáférés - beleértve a személyes eszközön (mobiltelefon, táblagép, számítógép, stb…) keresztül történő hozzáférést is -, önkéntes és opcionális. Célja a Bunge és munkavállalói közötti kommunikáció erősítése és a meglévő  kommunikációs csatornák bővítése.
                </li>
                <li>
                    Bármikor leállíthatom a weboldalak és/vagy alkalmazások használatát és törölhetem az alkalmazást.
                </li>
                <li>
                    A személyes eszköz(ei)m használatára a Bunge alkalmazandó irányelvei vonatkoznak, beleértve többek között a [link] alatt elérhető saját eszköz használatára vonatkozó Bring Your Own Device Policy ("BYOD") szabályozást.
                </li>
                <li>
                    A személyes eszközöm beszerzésének, használatának, karbantartásának, javításának és/vagy cseréjének költségeit, beleértve az adatforgalom költségeit is, a Bunge nem téríti meg.
                </li>
                <li>
                    Munkaidőn kívül a weboldalakat és/vagy az alkalmazásokat nem kell látogatnom és használnom. Nem vagyok jogosult túlórára vagy pótlékra, amennyiben a weboldalakat és/vagy alkalmazásokat munkaidőn kívül használom.
                </li>
                <li>
                    A honlapokhoz és/vagy alkalmazásokhoz tartozó jelszavak nem átruházhatók és megfelelően védeni kell az illetéktelen felhasználóktól.
                </li>
                <li>
                    Más személyek nem használhatják a weboldalakat és/vagy az alkalmazásokat az én nevemben, illetve nem használhatják a mobilkészülékemre küldött hitelesítési kódokat.
                </li>
                <li>
                    Köteles vagyok bizalmasan kezelni minden információt, amelyek a weboldalon és/vagy alkalmazáson elérhetők, vagy amelyek a mobileszközömre érkeznek.
                    Ezeket az információkat nem hozhatom nyilvánosságra és/vagy használhatom fel más célra, mint amire a Bunge magatartási kódexe, illetve a Bunge irányelvei és eljárásai engedélyeznek.
                </li>
                <li>
                    Vállalom, hogy haladéktalanul tájékoztatom a Bunge-t, ha a jelen űrlapon bejelentett mobilszámomban változás történik, és amennyiben továbbra is hozzá kívánok férni a weboldalakhoz és/vagy az alkalmazásokhoz.
                </li>
                <li>
                    Ha a készülékem elveszik, ellopják, vagy elidegenítésre kerül, haladéktalanul tájékoztatom a Bunge-t és beleegyezem, hogy a készülék elidegenítése előtt eltávolítom azokat a Bunge alkalmazást, amelyet telepítettem.
                </li>
                <li>
                    Elfogadom, hogy haladéktalanul tájékoztatom a Bungét, ha tudomást szerzek a weboldalakról és/vagy alkalmazások, illetve a hitelesítő adataim jogosulatlan használatáról.
                </li>
                <li>
                    Bármilyen visszaélés esetén, de legkésőbb azon a napon, amikor munkaviszonyom mengszüntetésre kerül a Bungénél a weboldalakhoz és az alkalmazásokhoz való hozzáférésemet deaktiválják.
                </li>
                <li>
                    Tudomásul veszem, hogy az alkalmazásokban található valamennyi tartalom a Bunge tulajdonát képezi, vagy azokat engedéllyel használja és bejegyzett védjegyek, melyeket szerzői jog véd. Beleegyezem, hogy ezeket a tartalmakat alkalmazásokon kívül, valamint a munkaviszonyom megszünése után sem használom fel a Bunge engedélye nélkül.
                </li>
                <li>
                    Jelen hozzáférési formára annak az országnak az alkalmazandó anyagi és eljárásjogi jogszabályai vonatkoznak, melyben alkalmazásban állok.
                </li>
                <li>
                    A Bunge nem vonható felelősségre visszaélésért, amennyiben megfelelően jártam el az illetéktelen felhasználás elleni védelmmel, vagy hitelesítő adataimat illetéktelen személy(ek)nek átadatam, vagy nem jelentettem azonnal a Bungénak az elveszett vagy ellopott eszközt.
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                Személyes adatok védelme
            </p>

            <p>
                Tájékoztatást kaptam arról, hogy a honlapokon és/vagy az alkalmazásokon keresztül feldolgozott személyes adataim összhangban állnak a Bunge európai munkavállalói adatvédelmi előírásokkal [link] és az alkalmazandó jogszabályokkal. A Bunge a személyes adataimat kizárólag a kétfaktoros hitelesítéshez és egyéb, a hozzáféréshez kapcsolódó információk küldése céljából használja fel annak érdekében, hogy a lent magadott hívószámú mobilkészüléken keresztül a Bunge weboldalaihoz és/vagy alkalmazásaihoz időről időre egy vagy több személyes eszközömön és/vagy a Bunge eszközein hozzáférjek.
            </p>

            <p>
                A lent megadott privát telefonszámomat a Bunge 5 (öt) napon belül törli attól a naptól számítva, hogy a rendszer használatát leállítom, vagy a hozzáférésemet a Bunge deaktiválja.
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

    .legal-policy-wrapper {
        margin-bottom: 40px;
        text-align: justify;
    }

    .legal-policy-content {
        max-height: 25vh;
        overflow-y: auto;
        padding-right: 30px;
    }

    .legal-policy-content li {
        padding-left: 15px;
        margin-top: 10px;
    }

</style>