<template>
    <div class="legal-policy-wrapper">

        <p class="text-center body-1 font-weight-bold mb-5">
            BUNGE网站和应用程序的使用条款和条件
        </p>

        <p class="mt-6 mb-4">
            通过在自愿注册到Bunge Limited和/或其下属企业的网站和/或应用程序，我确认已经阅读并理解了以下条款和条件并同意遵守。
        </p>

        <v-divider></v-divider>

        <div class="legal-policy-content">
            <ol>
                <li>
                    我确认访问Bunge网站和/或应用程序，包括通过个人设备访问，都是自愿的、可选的、其旨在加强Bunge与员工的沟通，，是为了在现有通信渠道的基础上，增加一个通信渠道。
                </li>
                <li>
                    在任何时候，我都可以停止使用这些网站和/或卸载应用程序并停止使用它们。
                </li>
                <li>
                    我的个人设备的使用将遵守Bunge的相关政策，包括但不限于《自带设备政策》（"BYOD"）
                </li>
                <li>
                    Bunge不要求我在工作之外查看这些网站和/或应用程序，如果我在工作时间之外访问，将无权获得任何加班费或额外工资。
                </li>
                <li>
                    网站和/或应用程序的密码是不可转让的，并且应该给予其保护而不被披露。
                </li>
                <li>
                    禁止其他人以我的名义使用网站和/或应用程序，或访问发送到我的移动设备号码上的任何认证码。
                </li>
                <li>
                    我必须尊重所有可在网站上获得或通过网站收到的信息的保密性，包括发送至我的移动设备的任何机密信息。我不得为任何目的而披露和/或使用该信息，除非得到邦吉公司根据其行为准则和政策程序给予的授权。
                </li>
                <li>
                    我同意及时告知Bunge我对于移动设备号码的任何改变，如我仍然希望访问这些网站和/或应用程序。
                </li>
                <li>
                    如果我的设备丢失或被盗，或以其他方式被处理，我将立即通知Bunge，并同意在处置任何设备之前，我将删除我的任何已经安装的Bunge APP。
                </li>
                <li>
                    我同意，如果我知道有任何使用我的证书访问网站和/或应用程序的未经授权的访问，我将立即通知Bunge。
                </li>
                <li>
                    如果出现任何误用或自我不再受雇于Bunge之日起，我对这些网站和应用程序的访问将被停用。
                </li>
                <li>
                    我知晓应用程序中的所有内容属于Bunge或被授权给Bunge，并且是受著作权法保护，并且我同意不在应用程序之外或在聘用关系解除后继续使用任何此类内容，无论其目的如何，除非得到Bunge的允许。
                </li>
                <li>
                    本条款和条件适用于我所就业国家的实体法和程序法。
                </li>
                <li>
                    如果我没有采用适当的方法保护我的证书、或者将其交给未经授权的第三方、或未立即向Bunge公司报告设备丢失或被盗的情况，则Bunge公司无须为此承担任何责任负责。
                </li>
            </ol>

            <p class="mt-6 mb-4 font-weight-bold">
                个人数据保护
            </p>

            <p>
                我在此被告知，我的个人数据将根据Bunge的政策和所适用法律通过此网站和/或应用程序处理。Bunge将仅为发送与双认证有关的代码为目的处理我的个人数据，以及其他与访问我下面列出的移动设备号码有关信息，以便我可以通过一个或多个我的移动设备和/或Bunge设备时不时地访问Bunge网站和/或应用程序。
            </p>

            <p>
                我在此提供的私人电话号码将在我停止使用该系统或访问权限被Bunge取消之日起5（五）天内被Bunge删除。
            </p>

            <p class="mt-6 mb-4 font-weight-bold">
                跨境数据传输
            </p>

            <p>
                我完全了解并同意以下内容。
            </p>

            <p>
                由于Bunge是一家跨国公司，当公司履行对本人的义务时，或当本人履行相关工作职能时，或出于本条款和条件所述的其他原因，本条款和条件中提到的本人的个人信息可能会被传送到中国以外的圣路易斯 密苏里州Bunge总部。
            </p>

            <p>
                我将告知公司我的个人数据的变化，以确保数据得到及时更新。我可以与人力资源部门联系，要求访问、纠正和删除我的个人资料，以及通知我的个人资料的变化。
            </p>
        </div>

        <v-divider></v-divider>

    </div>
</template>


<style scoped>

.legal-policy-wrapper {
    margin-bottom: 40px;
    text-align: justify;
}

.legal-policy-content {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 30px;
}

.legal-policy-content li {
    padding-left: 15px;
    margin-top: 10px;
}

</style>